import appLocaleData from 'react-intl/locale-data/pt';
import esMessages from '../locales/pt_BR';

const PtLang = {
    messages: {
        ...esMessages
    },
    locale: 'pt-BR',
    data: appLocaleData
};
export default PtLang;