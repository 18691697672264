import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import settingsReducer from './reducers/settings';

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    settings: settingsReducer
});

export function configureStore() {
    const store = createStore(rootReducer, composeEnhancers(
        applyMiddleware(...middlewares)
    ));

    return store;
}
