import React, { Component } from "react";
// import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Container, Row, Col, Button } from "reactstrap";

class Home extends Component {

    render() {
        const { intl } = this.props;

        return (
            <div>
                <section className="section-shaped my-0">
                    <div className="shape shape-style-1 shape-default shape-skew bg-gradient-teal bg-vhms"></div>
                    <Container className="shape-container d-flex">
                        <Col>
                            <img src="/images/vitruvian.png" className="vitruvian d-lg-block d-none" alt=""></img>
                            <Row>
                                <Col lg="6" className="px-0">
                                    <h1 className="display-3 text-white">
                                        {intl.formatMessage({ id: 'views.home.welcome' })}
                                        <small>
                                            <span>{intl.formatMessage({ id: 'views.home.welcome.sub' })}</span>
                                        </small>
                                    </h1>
                                    <p className="lead text-white">
                                        {intl.formatMessage({ id: 'views.home.welcome.text' })}
                                    </p>
                                    <div className="btn-wrapper mt-5">
                                        <a href={process.env.REACT_APP_APP_URI + "/signin"} className="mr-2">
                                            <Button color="secondary">{intl.formatMessage({ id: 'user.signin' })}</Button>
                                        </a>

                                        <a href={process.env.REACT_APP_APP_URI + "/signup"} className="mr-2">
                                            <Button color="success">{intl.formatMessage({ id: 'user.signup' })}</Button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </section>

                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid justify-content-center">
                            <Col lg="8" className="text-center">
                                <h2 className="display-3">{intl.formatMessage({ id: 'views.home.whatis' })}</h2>
                                <p className="lead">
                                    {intl.formatMessage({ id: 'views.home.whatis.text' })}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

// Home.propTypes = {
//     intl: intlShape.isRequired
// }

export default injectIntl(Home);
