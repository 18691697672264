import * as actionTypes from '../actions/actionTypes';
import AppLocale from "../../lang/index";

export const changeLocale = (locale) => {
    if (AppLocale[locale] === undefined) {
        locale = process.env.REACT_APP_DEFAULT_LOCALE;
    }

    localStorage.setItem('currentLanguage', locale);
    return {
        type: actionTypes.CHANGE_LOCALE,
        payload: locale
    }
}
