import React, { Component } from "react";

class Home extends Component {

    render() {
        return (
            <div>
                <div className="position-relative">
                    <section className="section-shaped my-0">
                        <div className="shape shape-style-1 shape-default shape-skew">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <div className="container shape-container d-flex">
                            <div className="col px-0">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h1 className="display-3  text-white">A beautiful Design System
									<span>completed with examples</span>
                                        </h1>
                                        <p className="lead  text-white">The design system comes with four pre-built pages to help
                                        you get started faster. You can change the text and images and you're good to
                                go.</p>
                                        <div className="btn-wrapper">
                                            <a type="" className="btn mb-3 mb-sm-0 btn-icon btn-info" href="https://demos.creative-tim.com/argon-design-system/docs/components/alerts.html">
                                                <span className="btn-inner--icon">
                                                    <i className="fa fa-code" />
                                                </span>
                                                <span className="btn-inner--text">
                                                    Components
										</span>
                                            </a>
                                            <a type="" className="btn mb-3 mb-sm-0 btn-icon btn-white" href="https://www.creative-tim.com/product/argon-design-system">
                                                <span className="btn-inner--icon">
                                                    <i className="ni ni-cloud-download-95" />
                                                </span>
                                                <span className="btn-inner--text">
                                                    Download HTML
										</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section className="section section-lg pt-lg-0 mt--200">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="row row-grid">
                                    <div className="col-lg-4">
                                        <div className="card border-0 card-lift--hover shadow">
                                            <div className="card-body py-5">
                                                <div className="icon icon-shape mb-4 icon-shape-primary rounded-circle">
                                                    <i className="ni ni-check-bold" />
                                                </div>
                                                <h6 className="text-primary text-uppercase">Download Argon</h6>
                                                <p className="description mt-3">Argon is a great free UI package based on Bootstrap 4
                                    that includes the most important components and features.</p>
                                                <div>
                                                    <span className="badge badge-primary badge-pill">design</span>
                                                    <span className="badge badge-primary badge-pill">system</span>
                                                    <span className="badge badge-primary badge-pill">creative</span>
                                                </div>
                                                <a type="" className="btn mt-4 btn-primary" href="/">
                                                    Learn more
										</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card border-0 card-lift--hover shadow">
                                            <div className="card-body py-5">
                                                <div className="icon icon-shape mb-4 icon-shape-success rounded-circle">
                                                    <i className="ni ni-istanbul" />
                                                </div>
                                                <h6 className="text-success text-uppercase">Build Something</h6>
                                                <p className="description mt-3">Argon is a great free UI package based on Bootstrap
                                    4 that includes the most important components and features.</p>
                                                <div>
                                                    <span className="badge badge-success badge-pill">business</span>
                                                    <span className="badge badge-success badge-pill">vision</span>
                                                    <span className="badge badge-success badge-pill">success</span>
                                                </div>
                                                <a type="" className="btn mt-4 btn-success" href="/">
                                                    Learn more
										</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card border-0 card-lift--hover shadow">
                                            <div className="card-body py-5">
                                                <div className="icon icon-shape mb-4 icon-shape-warning rounded-circle">
                                                    <i className="ni ni-planet" />
                                                </div>
                                                <h6 className="text-warning text-uppercase">Prepare Launch</h6>
                                                <p className="description mt-3">Argon is a great free UI package based on Bootstrap
                                    4 that includes the most important components and features.</p>
                                                <div>
                                                    <span className="badge badge-warning badge-pill">marketing</span>
                                                    <span className="badge badge-warning badge-pill">product</span>
                                                    <span className="badge badge-warning badge-pill">launch</span>
                                                </div>
                                                <a type="" className="btn mt-4 btn-warning" href="/">
                                                    Learn more
										</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg">
                    <div className="container">
                        <div className="row row-grid align-items-center">
                            <div className="col-md-6 order-md-2">
                                <img src="img/theme/promo-1.png" className="img-fluid floating" alt="" />
                            </div>
                            <div className="col-md-6 order-md-1">
                                <div className="pr-md-5">
                                    <div className="icon icon-shape mb-5 icon-lg icon-shape-success shadow rounded-circle">
                                        <i className="ni ni-settings-gear-65" />
                                    </div>
                                    <h3>Awesome features</h3>
                                    <p>The kit comes with three pre-built pages to help you get started faster. You can change
                            the text and images and you're good to go.</p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <span className="badge mr-3 badge-success badge-circle">
                                                    <i className="ni ni-settings-gear-65" />
                                                </span>
                                                <h6 className="mb-0">Carefully crafted components</h6>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <span className="badge mr-3 badge-success badge-circle">
                                                    <i className="ni ni-html5" />
                                                </span>
                                                <h6 className="mb-0">Amazing page examples</h6>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <span className="badge mr-3 badge-success badge-circle">
                                                    <i className="ni ni-satisfied" />
                                                </span>
                                                <h6 className="mb-0">Super friendly support team</h6>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section bg-secondary">
                    <div className="container">
                        <div className="row row-grid align-items-center">
                            <div className="col-md-6">
                                <div className="card bg-default shadow border-0">
                                    <img className="card-img-top" alt="" data-src="img/theme/img-1-1200x1000.jpg" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" lazy="loading" />
                                    <blockquote className="card-blockquote">
                                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" className="svg-bg">
                                            <polygon points="0,52 583,95 0,95" className="fill-default" />
                                            <polygon points="0,42 583,95 683,0 0,95" opacity=".2" className="fill-default" />
                                        </svg>
                                        <h4 className="display-3 font-weight-bold text-white">Design System</h4>
                                        <p className="lead text-italic text-white">The Arctic Ocean freezes every winter and much of
                                the sea-ice then thaws every summer, and that process will continue whatever
                                happens.</p>
                                    </blockquote>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="pl-md-5">
                                    <div className="icon icon-shape mb-5 icon-lg icon-shape-warning shadow rounded-circle">
                                        <i className="ni ni-settings" />
                                    </div>
                                    <h3>Our customers</h3>
                                    <p className="lead">Don't let your uses guess by attaching tooltips and popoves to any element.
                            Just make sure you enable them first via JavaScript.</p>
                                    <p>The kit comes with three pre-built pages to help you get started faster. You can change
                            the text and images and you're good to go.</p>
                                    <p>The kit comes with three pre-built pages to help you get started faster. You can change
                            the text and images and you're good to go.</p>
                                    <a href="/" className="font-weight-bold text-warning mt-5">A beautiful UI Kit for impactful
                            websites</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section section-shaped my-0 overflow-hidden">
                    <div className="shape shape-style-1 bg-gradient-warning shape-skew">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <div className="container py-0">
                        <div className="row row-grid align-items-center">
                            <div className="col-md-6 order-lg-2 ml-lg-auto">
                                <div className="position-relative pl-md-5">
                                    <img alt="" src="img/ill/ill-2.svg" className="img-center img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <div className="d-flex px-3">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-white icon-lg shadow rounded-circle text-primary">
                                            <i className="ni ni-building" />
                                        </div>
                                    </div>
                                    <div className="pl-4">
                                        <h4 className="display-3 text-white">Modern Interface</h4>
                                        <p className="text-white">The Arctic Ocean freezes every winter and much of the sea-ice then
                                thaws every summer, and that process will continue whatever happens.</p>
                                    </div>
                                </div>
                                <div className="card shadow-lg--hover mt-5 shadow">
                                    <div className="card-body">
                                        <div className="d-flex px-3">
                                            <div>
                                                <div className="icon icon-shape bg-gradient-success shadow rounded-circle text-white">
                                                    <i className="ni ni-satisfied" />
                                                </div>
                                            </div>
                                            <div className="pl-4">
                                                <h5 className="title text-success">Awesome Support</h5>
                                                <p>The Arctic Ocean freezes every winter and much of the sea-ice then thaws
                                    every summer, and that process will continue whatever happens.</p>
                                                <a href="/" className="text-success">Learn more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-lg--hover mt-5 shadow">
                                    <div className="card-body">
                                        <div className="d-flex px-3">
                                            <div>
                                                <div className="icon icon-shape bg-gradient-warning shadow rounded-circle text-white">
                                                    <i className="ni ni-active-40" />
                                                </div>
                                            </div>
                                            <div className="pl-4">
                                                <h5 className="title text-warning">Modular Components</h5>
                                                <p>The Arctic Ocean freezes every winter and much of the sea-ice then thaws
                                    every summer, and that process will continue whatever happens.</p>
                                                <a href="/" className="text-warning">Learn more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg">
                    <div className="container">
                        <div className="row justify-content-center text-center mb-lg">
                            <div className="col-lg-8">
                                <h2 className="display-3">The amazing Team</h2>
                                <p className="lead text-muted">According to the National Oceanic and Atmospheric Administration,
                        Ted, Scambos, NSIDClead scentist, puts the potentially record maximum.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
                                <div className="px-4">
                                    <img alt="" className="rounded-circle img-center img-fluid shadow shadow-lg--hover" data-src="img/theme/team-1-800x800.jpg" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" lazy="loading" />
                                    <div className="pt-4 text-center">
                                        <h5 className="title">
                                            <span className="d-block mb-1">Ryan Tompson</span>
                                            <small className="h6 text-muted">Web Developer</small>
                                        </h5>
                                        <div className="mt-3">
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-warning" href="/">
                                                <i className="fa fa-twitter" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-warning" href="/">
                                                <i className="fa fa-facebook" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-warning" href="/">
                                                <i className="fa fa-dribbble" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
                                <div className="px-4">
                                    <img alt="" className="rounded-circle img-center img-fluid shadow shadow-lg--hover" data-src="img/theme/team-2-800x800.jpg" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" lazy="loading" />

                                    <div className="pt-4 text-center">
                                        <h5 className="title">
                                            <span className="d-block mb-1">Romina Hadid</span>
                                            <small className="h6 text-muted">Marketing Strategist</small>
                                        </h5>
                                        <div className="mt-3">
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-primary" href="/">
                                                <i className="fa fa-twitter" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-primary" href="/">
                                                <i className="fa fa-facebook" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-primary" href="/">
                                                <i className="fa fa-dribbble" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
                                <div className="px-4">
                                    <img alt="" className="rounded-circle img-center img-fluid shadow shadow-lg--hover" data-src="img/theme/team-3-800x800.jpg" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" lazy="loading" />
                                    <div className="pt-4 text-center">
                                        <h5 className="title">
                                            <span className="d-block mb-1">Alexander Smith</span>
                                            <small className="h6 text-muted">UI/UX Designer</small>
                                        </h5>
                                        <div className="mt-3">
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-info" href="/">
                                                <i className="fa fa-twitter" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-info" href="/">
                                                <i className="fa fa-facebook" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-info" href="/">
                                                <i className="fa fa-dribbble" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
                                <div className="px-4">
                                    <img alt="" className="rounded-circle img-center img-fluid shadow shadow-lg--hover" data-src="img/theme/team-4-800x800.jpg" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" lazy="loading" />
                                    <div className="pt-4 text-center">
                                        <h5 className="title">
                                            <span className="d-block mb-1">John Doe</span>
                                            <small className="h6 text-muted">Founder and CEO</small>
                                        </h5>
                                        <div className="mt-3">
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-success" href="/">
                                                <i className="fa fa-twitter" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-success" href="/">
                                                <i className="fa fa-facebook" />
                                            </a>
                                            <a type="" className="btn rounded-circle btn-icon-only btn-icon btn-success" href="/">
                                                <i className="fa fa-dribbble" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg pt-0">
                    <div className="container">
                        <div className="card border-0 shadow-lg bg-gradient-warning">
                            <div className="p-5">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <h3 className="text-white">We made website building easier for you.</h3>
                                        <p className="lead text-white mt-3">I will be the leader of a company that ends up being
                                worth billions of dollars, because I got the answers. I understand culture.</p>
                                    </div>
                                    <div className="col-lg-3 ml-lg-auto">
                                        <a type="" className="btn btn-block btn-white btn-lg" href="https://www.creative-tim.com/product/vue-argon-design-system">
                                            Download Vue
															</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-shaped my-0 overflow-hidden">
                    <div className="shape shape-style-3 bg-gradient-default shape-skew">
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <div className="container pt-lg pb-300">
                        <div className="row text-center justify-content-center">
                            <div className="col-lg-10">
                                <h2 className="display-3 text-white">Build something</h2>
                                <p className="lead text-white">According to the National Oceanic and Atmospheric Administration,
    Ted, Scambos, NSIDClead scentist, puts the potentially record low maximum sea ice extent
                        tihs year down to low ice.</p>
                            </div>
                        </div>
                        <div className="row row-grid mt-5">
                            <div className="col-lg-4">
                                <div className="icon icon-shape icon-lg bg-gradient-white shadow text-primary" round="">
                                    <i className="ni ni-settings" />
                                </div>
                                <h5 className="text-white mt-3">Building tools</h5>
                                <p className="text-white mt-3">Some quick example text to build on the card title and make up the
                        bulk of the card's content.</p>
                            </div>
                            <div className="col-lg-4">
                                <div className="icon icon-shape icon-lg bg-gradient-white shadow text-primary" round="">
                                    <i className="ni ni-ruler-pencil" />
                                </div>
                                <h5 className="text-white mt-3">Grow your market</h5>
                                <p className="text-white mt-3">Some quick example text to build on the card title and make up the
                        bulk of the card's content.</p>
                            </div>
                            <div className="col-lg-4">
                                <div className="icon icon-shape icon-lg bg-gradient-white shadow text-primary" round="">
                                    <i className="ni ni-atom" />
                                </div>
                                <h5 className="text-white mt-3">Launch time</h5>
                                <p className="text-white mt-3">Some quick example text to build on the card title and make up the
                        bulk of the card's content.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg pt-lg-0 section-contact-us">
                    <div className="container">
                        <div className="row justify-content-center mt--300">
                            <div className="col-lg-8">
                                <div className="card shadow bg-gradient-secondary">
                                    <div className="card-body p-lg-5">
                                        <h4 className="mb-1">Want to work with us?</h4>
                                        <p className="mt-0">Your project is very important to us.</p>
                                        <div className="form-group mt-5 input-group input-group-alternative">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="ni ni-user-run" />
                                                </span>
                                            </div>
                                            <input aria-describedby="addon-right addon-left" placeholder="Your name" className="form-control" />
                                        </div>
                                        <div className="form-group input-group input-group-alternative">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="ni ni-email-83" />
                                                </span>
                                            </div>
                                            <input aria-describedby="addon-right addon-left" placeholder="Email address" className="form-control" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <textarea name="name" rows="4" cols="80" placeholder="Type a message..." className="form-control form-control-alternative" />
                                        </div>
                                        <button type="button" className="btn btn-block btn-default btn-lg" round="">
                                            Send Message
																	</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-lg">
                    <div className="container">
                        <div className="row row-grid justify-content-center">
                            <div className="col-lg-8 text-center">
                                <h2 className="display-3">Do you love this awesome
                                        <span className="text-success">Design System for Bootstrap 4?</span>
                                </h2>
                                <p className="lead">Cause if you do, it can be yours for FREE. Hit the button below to navigate to
Creative Tim where you can find the Design System in HTML. Start a new project or give an
                        old Bootstrap project a new look!</p>
                                <div className="btn-wrapper">
                                    <a href="https://www.creative-tim.com/product/vue-argon-design-system" className="btn btn-primary mb-3 mb-sm-0">Download Vue</a>
                                </div>
                                <div className="text-center">
                                    <h4 className="display-4 mb-5 mt-5">Available on these technologies</h4>
                                    <div className="row justify-content-center">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Home;
