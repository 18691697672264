import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Navbar, Nav, NavbarToggler, Collapse, Container, Row, Col, NavItem, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

class NavbarLayout extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    close() {
        this.setState({
            isOpen: false
        });
    }

    render() {
        const { intl } = this.props;

        return (
            <header className="header-global">
                <nav className="navbar navbar-main navbar-expand-lg navbar-light navbar-transparent">
                    <Container>
                        <NavLink to="/" className="navbar-brand">
                            <img src="/images/brand/logow.png" alt="VHMS"></img>
                        </NavLink>

                        <NavbarToggler onClick={this.toggle}>
                            <span className="navbar-toggler-icon"></span>
                        </NavbarToggler>

                        <Collapse isOpen={this.state.isOpen} navbar>
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand">
                                        <NavLink to="/">
                                            <img src="/images/brand/logo.png" alt="VHMS"></img>
                                        </NavLink>
                                    </Col>
                                    <Col className="collapse-close">
                                        <NavbarToggler onClick={this.close}>
                                            <span className="navbar-toggler-icon"></span>
                                            <span className="navbar-toggler-icon"></span>
                                        </NavbarToggler>
                                    </Col>
                                </Row>
                            </div>
                            <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                                {/* <li aria-haspopup="true" className="dropdown nav-item dropdown">
                                    <a href="/" data-toggle="dropdown" role="button" className="nav-link">
                                        <i className="ni ni-ui-04 d-lg-none" />
                                        <span className="nav-link-inner--text">Components</span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-xl">
                                        <div className="dropdown-menu-inner">

                                            <NavLink to="/documentation" className="media d-flex align-items-center">
                                                <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <i className="ni ni-ui-04" />
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h5 className="heading text-warning mb-md-1">Components</h5>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        Learn how to use Argon compiling Scss, change brand colors and more.
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </ul>
                                </li> */}
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        {intl.formatMessage({ id: 'navbar.why' })}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xl">
                                        <DropdownItem className="my-2">
                                            <NavLink to="/documentation" className="media d-flex align-items-center">
                                                <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                                    <i className="ni ni-spaceship" />
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="heading text-primary mb-md-1">
                                                        {intl.formatMessage({ id: 'navbar.why.privacy.title' })}
                                                    </h6>
                                                    <p className="description mb-0">
                                                        {intl.formatMessage({ id: 'navbar.why.privacy.text' })}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {/* <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        <IntlMessages id="navbar.services" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <NavLink to="/services">
                                            <DropdownItem onClick={this.close}>
                                                Services
                                            </DropdownItem>
                                        </NavLink>
                                        <DropdownItem>Option 2</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown> */}
                                <NavItem onClick={this.close}>
                                    <NavLink to="/services" className="nav-link">
                                        {intl.formatMessage({ id: 'navbar.services' })}
                                    </NavLink>
                                </NavItem>
                                <NavItem onClick={this.close}>
                                    <NavLink to="/pricing" className="nav-link">
                                        {intl.formatMessage({ id: 'navbar.pricing' })}
                                    </NavLink>
                                </NavItem>
                            </ul>
                            <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                                <li className="nav-item d-none d-lg-block ml-lg-4">
                                    <a href={process.env.REACT_APP_APP_URI + "/signin"} className="btn btn-neutral btn-icon">
                                        <span className="btn-inner--icon">
                                            <i className="fa fa-cloud-download mr-2" />
                                        </span>
                                        <span className="nav-link-inner--text">{intl.formatMessage({ id: 'user.signinup' })}</span>
                                    </a>
                                </li>
                            </ul>
                        </Collapse>
                    </Container>
                </nav>
            </header>
        )
    }
}

export default injectIntl(NavbarLayout);
