import React, { Component } from 'react';

import Navbar from './Navbar';
import Footer from './Footer';

class Layout extends Component {
    render() {
        return (
            <div>
                <Navbar></Navbar>
                <main>
                    {this.props.children}
                </main>
                <Footer></Footer>
            </div>
        )
    }
}

export default Layout;
