/* Gogo Language Texts

Table of Contents

01.General
02.User 
03.Navbar
04.Footer
05.Errors

01.01.Home Page
*/

module.exports = {
    /* 01.General */
    "general.copyright": "VHMS © 2018 All Rights Reserved.",
    "general.name.short": "VHMS",
    "general.name.long": "Virtual Health Medical Solutions",

    /* 02.User */
    "user.signin": "Entrar",
    "user.signup": "Registrar",
    "user.signinup": "Entrar ou Registrar",

    /* 03.Navbar */
    "navbar.why": "Porquê VHMS?",
    "navbar.services": "Serviços",
    "navbar.pricing": "Preços",
    "navbar.why.privacy.title": "Privacidade",
    "navbar.why.privacy.text": "Nós nos preocupamos com a sua privacidade!",

    /* 04.Footer */
    "footer.blog": "Blog",
    "footer.privacy": "Privacidade",
    "footer.cookies": "Cookies",
    "footer.about": "Sobre Nós",

    /* 05.Errors */
    "layouts.error-title": "Ooops... Ocorreu um erro!",
    "layouts.error-code": "Error code",
    "layouts.go-back-home": "GO BACK HOME",

    /*01.01.Home Page*/
    "views.home.welcome": "Bem-vindo ao VHMS",
    "views.home.welcome.sub": "Seu Gerenciador de Saúde Pessoal",
    "views.home.welcome.text": "VHMS é um centro de registro médico online que fornece os meios para que você ou um profissional médico possa visualizar seu histórico cronológico médico e odontológico em um ambiente seguro e protegido.",
    "views.home.whatis": "O que é VHMS?",
    "views.home.whatis.text": "VHMS é um centro de registro médico online que fornece os meios para que você ou um profissional médico possa visualizar seu histórico cronológico médico e odontológico em um ambiente seguro e protegido.",

};
