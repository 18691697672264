import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import detectBrowserLanguage from 'detect-browser-language';

// Locales
import AppLocale from "./lang/index";
import { changeLocale } from "./store/actions/index";

// STYLES
import './assets/scss/argon/argon.css';
import './assets/scss/vhms.css';
import './assets/icons/nucleo/css/nucleo.css';
import './assets/icons/font-awesome/css/font-awesome.min.css';

// Layout
import Layout from "./components/layout/Layout";

// Public
import Home from "./views/Home";
import Test from "./views/Test";


class App extends Component {

    componentWillMount() {
        this.props.changeLocale(detectBrowserLanguage());
    }

    render() {
        const currentAppLocale = AppLocale[this.props.locale];

        return (
            <Fragment>
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                    <Layout>
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/test" exact component={Test} />
                            <Redirect to="/" />
                        </Switch>
                    </Layout>
                </IntlProvider>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        locale: state.settings.locale
    };
};

export default withRouter(connect(mapStateToProps, { changeLocale })(App));

