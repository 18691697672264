import * as actionTypes from '../actions/actionTypes';
import AppLocale from "../../lang/index";

const initialState = {
    locale: (localStorage.getItem('currentLanguage') && AppLocale[localStorage.getItem('currentLanguage')] !== undefined) ? localStorage.getItem('currentLanguage') : process.env.REACT_APP_DEFAULT_LOCALE,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_LOCALE:
            return { ...state, locale: action.payload };

        default: return { ...state };
    }
}