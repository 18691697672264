/* Gogo Language Texts

Table of Contents

01.General
02.User 
03.Navbar
04.Footer
05.Errors

01.01.Home Page
*/

module.exports = {
  /* 01.General */
  "general.copyright": "VHMS © 2018 All Rights Reserved.",
  "general.name.short": "VHMS",
  "general.name.long": "Virtual Health Medical Solutions",

  /* 02.User */
  "user.signin": "Sign In",
  "user.signup": "Register",
  "user.signinup": "Signin or Register",

  /* 03.Navbar */
  "navbar.why": "Why VHMS?",
  "navbar.services": "Services",
  "navbar.pricing": "Pricing",
  "navbar.why.privacy.title": "Privacy",
  "navbar.why.privacy.text": "We care about your privacy!",

  /* 04.Footer */
  "footer.blog": "Blog",
  "footer.privacy": "Privacy",
  "footer.cookies": "Cookies",
  "footer.about": "About Us",

  /* 05.Errors */
  "layouts.error-title": "Ooops... looks like an error occurred!",
  "layouts.error-code": "Error code",
  "layouts.go-back-home": "GO BACK HOME",

  /*01.01.Home Page*/
  "views.home.welcome": "Welcome to VHMS",
  "views.home.welcome.sub": "Your Personal Health Manager",
  "views.home.welcome.text": "VHMS is an online medical registry center providing the means for you or a medical professional to view your chronological medical and dental history in a secure and safe environment.",
  "views.home.whatis": "What is VHMS?",
  "views.home.whatis.text": "VHMS is an online medical registry center providing the means for you or a medical professional to view your chronological medical and dental history in a secure and safe environment.",

};
