import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';

class FooterLayout extends Component {
    render() {
        const { intl } = this.props;

        return (
            <footer className="footer bg-dark">
                <Container>
                    <Row className="align-items-center justify-content-xl-between">
                        <Col xl="6">
                            <div className="copyright text-center text-xl-left text-muted">
                                <NavLink to="/" className="font-weight-bold ml-1">{intl.formatMessage({ id: 'general.name.short' })}</NavLink> &copy; {new Date().getFullYear()}
                            </div>
                        </Col>
                        <Col xl="6">
                            <Nav className="nav-footer justify-content-center justify-content-xl-end">
                                <NavItem>
                                    <NavLink to="/blog" className="nav-link">{intl.formatMessage({ id: 'footer.blog' })}</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="/privacy" className="nav-link">{intl.formatMessage({ id: 'footer.privacy' })}</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="/cookies" className="nav-link">{intl.formatMessage({ id: 'footer.cookies' })}</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink to="/about" className="nav-link">{intl.formatMessage({ id: 'footer.about' })}</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}

export default injectIntl(FooterLayout);
